import service from '@/utils/request'; // 用户登录

export var login = function login(data) {
  return service({
    url: '/user/login',
    method: 'POST',
    data: data
  });
}; // 用户注销

export var loginOut = function loginOut(data) {
  return service({
    url: '/user/loginOut',
    method: 'POST',
    data: data
  });
}; // 获取验证码

export var captcha = function captcha(data) {
  return service({
    url: '/base/captcha',
    method: 'POST',
    data: data
  });
}; // 用户注册

export var register = function register(data) {
  return service({
    url: '/user/register',
    method: 'POST',
    data: data
  });
}; // 修改密码

export var changePassword = function changePassword(data) {
  return service({
    url: '/user/changePassword',
    method: 'POST',
    data: data
  });
}; // 分页获取用户列表

export var getUserList = function getUserList(data) {
  return service({
    url: '/user/list',
    method: 'POST',
    data: data
  });
}; // 设置用户权限

export var setUserAuthority = function setUserAuthority(uuid, data) {
  return service({
    url: "/user/setAuthority/".concat(uuid),
    method: 'PUT',
    data: data
  });
}; // 删除用户

export var deleteUser = function deleteUser(id) {
  return service({
    url: "/user/".concat(id),
    method: 'DELETE'
  });
}; // 设置用户信息

export var setUserInfo = function setUserInfo(data) {
  return service({
    url: '/user/setUserInfo',
    method: 'put',
    data: data
  });
}; // 设置用户信息

export var getMemberList = function getMemberList(params) {
  return service({
    url: '/user/list',
    method: 'get',
    params: params
  });
}; // 批量添加积分

export var scoreAdd = function scoreAdd(data) {
  return service({
    url: '/user/score/add',
    method: 'post',
    data: data
  });
}; // 空投商品

export var airdrop = function airdrop(data) {
  return service({
    url: '/user/airdrop',
    method: 'post',
    data: data
  });
};