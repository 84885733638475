import service from '@/utils/request'; // 创建SysDictionary

export var createSysDictionary = function createSysDictionary(data) {
  return service({
    url: '/dictionary',
    method: 'POST',
    data: data
  });
}; // 删除SysDictionary

export var deleteSysDictionary = function deleteSysDictionary(id) {
  return service({
    url: "/dictionary/".concat(id),
    method: 'DELETE'
  });
}; // 更新SysDictionary

export var updateSysDictionary = function updateSysDictionary(id, data) {
  return service({
    url: "/dictionary/".concat(id),
    method: 'PUT',
    data: data
  });
}; // 用id或者type查询SysDictionary

export var findSysDictionary = function findSysDictionary(type) {
  return service({
    url: "/dictionary/find/".concat(type),
    method: 'GET'
  });
}; // 分页获取SysDictionary列表

export var getSysDictionaryList = function getSysDictionaryList(params) {
  return service({
    url: '/dictionary/list',
    method: 'GET',
    params: params
  });
};