import "core-js/modules/es.object.to-string";
import _objectSpread from "C:/Users/123456/Desktop/bor/\u4EA8\u5143\u5B87\u5B99/bor-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import axios from 'axios'; // 引入axios

import { Message, Loading } from 'element-ui';
import { store } from '@/store/index';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999
});
var acitveAxios = 0;
var loadingInstance;
var timer;

var showLoading = function showLoading() {
  acitveAxios++;

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(function () {
    if (acitveAxios > 0) {
      loadingInstance = Loading.service({
        fullscreen: true
      });
    }
  }, 400);
};

var closeLoading = function closeLoading() {
  acitveAxios--;

  if (acitveAxios <= 0) {
    clearTimeout(timer);
    loadingInstance && loadingInstance.close();
  }
}; // http request 拦截器


service.interceptors.request.use(function (config) {
  if (!config.donNotShowLoading) {
    showLoading();
  }

  var token = store.getters['user/token']; // config.data = JSON.stringify(config.data)

  var headers = {};
  if (config.config && config.config.headers) headers = config.config.headers;
  console.log(config);
  config.headers = _objectSpread({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }, headers);
  return config;
}, function (error) {
  console.log(error);
  closeLoading();
  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return Promise.reject(error);
}); // http response 拦截器

service.interceptors.response.use(function (response) {
  closeLoading();

  if (response.headers['new-token']) {
    store.commit('user/setToken', response.headers['new-token']);
  }

  if (response.data.code === 200 || response.data.code === 0 || response.headers.success === true) {
    console.log(response);
    return response.data;
  } else {
    Message({
      showClose: true,
      message: response.data.msg || decodeURI(response.headers.msg),
      type: 'error'
    });

    if (response.data.data && response.data.data.reload) {
      store.commit('user/LoginOut');
    }

    return Promise.reject(response.data.msg);
  }
}, function (error, config) {
  closeLoading(); // console.log(error)
  // console.log(config)

  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return Promise.reject(error);
});
export default service;