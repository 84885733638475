import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
var baseRouters = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/view/login/login.vue');
  }
}, {
  path: '/register',
  name: 'register',
  component: function component() {
    return import('@/view/login/register.vue');
  }
}]; // 需要通过后台数据来生成的组件

var createRouter = function createRouter() {
  return new Router({
    routes: baseRouters
  });
};

var router = createRouter();
export default router;