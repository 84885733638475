import service from '@/utils/request'; // 用户登录 获取动态路由

export var asyncMenu = function asyncMenu() {
  return service({
    url: '/menu/async',
    method: 'GET'
  });
}; // 获取menu列表

export var getMenuList = function getMenuList(data) {
  return service({
    url: '/menu/list',
    method: 'GET',
    data: data
  });
}; // 新增基础menu

export var addBaseMenu = function addBaseMenu(data) {
  return service({
    url: '/menu',
    method: 'POST',
    data: data
  });
}; // 获取基础路由列表

export var getBaseMenuTree = function getBaseMenuTree() {
  return service({
    url: '/menu',
    method: 'GET'
  });
}; // 添加用户menu关联关系

export var addMenuAuthority = function addMenuAuthority(data) {
  return service({
    url: '/menu/addMenuAuthority',
    method: 'POST',
    data: data
  });
}; // 获取用户menu关联关系

export var getMenuAuthority = function getMenuAuthority(data) {
  return service({
    url: '/menu/getMenuAuthority',
    method: 'POST',
    data: data
  });
}; // 删除菜单

export var deleteBaseMenu = function deleteBaseMenu(id) {
  return service({
    url: "/menu/".concat(id),
    method: 'DELETE'
  });
}; // 修改menu列表

export var updateBaseMenu = function updateBaseMenu(id, data) {
  return service({
    url: "/menu/".concat(id),
    method: 'PUT',
    data: data
  });
}; // 根据id获取菜单

export var getBaseMenuById = function getBaseMenuById(id) {
  return service({
    url: "/menu/find/".concat(id),
    method: 'GET'
  });
};